import { Injectable } from '@angular/core';
import { biqHelper } from '@biqdev/ng-helper';
import * as CryptoJS from 'crypto-js';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { environment } from 'src/environments/environment';
import { AppointmentTypes } from './enums/AppointmentTypes';
@Injectable({
  providedIn: 'root'
})
export class SharedService {
  key = environment.key
  tokenFromUI: string = environment.key;
  encrypted: any = "";
  decrypted: string;

  io: any = "Empty";

  request: string;
  responce: string;
  constructor(private toastr: ToastrService) { }

  setIo() {
    this.io = "Initialized";
  }

  getIo() {
    return this.io;
  }

  encryptData(data) {
    Object.keys(data).forEach(key => {
      const val = data[key];
      let enc_first = CryptoJS.AES.encrypt(val, this.key).toString();
      data[key] = enc_first;
    });
    return data
  }

  decryptData(data) {
    Object.keys(data).forEach(key => {
      let val = data[key];
      const decrypted = CryptoJS.AES.decrypt(val, this.key);
      data[key] = decrypted.toString(CryptoJS.enc.Utf8);
    });
    return data
  }
  // /* Method for Encryption */
  // encryptUsingAES256(data) {
  //   let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
  //   let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
  //   Object.keys(data).forEach(key => {
  //     const val = data[key];
  //     let encrypted = CryptoJS.AES.encrypt(
  //       JSON.stringify(val), _key, {
  //         keySize: 16,
  //         iv: _iv,
  //         mode: CryptoJS.mode.ECB,
  //         padding: CryptoJS.pad.Pkcs7
  //       });
  //     data[key] = encrypted.toString();;
  //   });
  //  return data
  // }
  // decryptSingle(data) {
  //   console.log(data)
  //   let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
  //   let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);

  //   let decrypted = CryptoJS.AES.decrypt(
  //     data, _key, {
  //       keySize: 16,
  //       iv: _iv,
  //       mode: CryptoJS.mode.ECB,
  //       padding: CryptoJS.pad.Pkcs7
  //     }).toString(CryptoJS.enc.Utf8);
  //     console.log(decrypted)
  //     return decrypted
  // }
  /* Method for Encryption */
  encryptUsingAES256(data) {
    console.log(data, "data enc")
    var newkey = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    // var iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);

    Object.keys(data).forEach(key => {
      const val = data[key];
      var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(val.toString()), newkey,
        {
          iv: CryptoJS.enc.Hex.parse('00000000000000000000000000000000'),
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
        });
      data[key] = encrypted.toString();;
    });
    return data
  }
  decryptSingle(data) {
    console.log(data)
    //   ({ciphertext: crypted}, cryptkey, {
    //     iv: CryptoJS.enc.Hex.parse('00000000000000000000000000000000'),
    //     mode: CryptoJS.mode.CBC,
    //     padding: CryptoJS.pad.Pkcs7
    // });
    const crypted = CryptoJS.enc.Base64.parse(data);
    var key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    // var iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    var decrypted = CryptoJS.AES.decrypt({ ciphertext: crypted }, key, {
      iv: CryptoJS.enc.Hex.parse('00000000000000000000000000000000'),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    console.log(decrypted.toString(CryptoJS.enc.Utf8), "decrypted.toString(CryptoJS.enc.Utf8)")
    return decrypted.toString(CryptoJS.enc.Utf8);
  }
  decryptUsingAES256(data) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    Object.keys(data).forEach(key => {
      const val = data[key];
      let decrypted = CryptoJS.AES.decrypt(
        val, _key, {
        keySize: 16,
        iv: _iv,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      }).toString(CryptoJS.enc.Utf8);
      data[key] = decrypted
    });
    return data
  }

  convertDateFormat(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }
  clean(obj) {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined || obj[propName] === "") {
        delete obj[propName];
      }
    }
    return obj
  }
  changedData(newData, oldData) {
    Object.keys(newData).reduce((diff, key) => {
      if (oldData[key] === newData[key]) return diff
      return {
        ...diff,
        [key]: newData[key]
      }
    }, {})
  }
  timediff(dt2, dt1, res_type) {
    if (res_type == 'minutes') {
      var diff = (dt2.getTime() - dt1.getTime()) / 1000;
      diff /= 60;
      return Math.abs(Math.round(diff));
    }
    if (res_type == 'hours') {
      var diff = (dt2.getTime() - dt1.getTime()) / 1000;
      diff /= (60 * 60);
      return Math.abs(Math.round(diff));
    }
  }

  errorHandlerDefault(err) {
    if ( environment.local_dev ) {
      console.trace(err);
    }

    let errorMsg = _.get(err, 'message');
    try {

      if (biqHelper.isNull(errorMsg) && typeof err === 'object') {
        errorMsg = _.get(err, 'error.message');
        if (biqHelper.isNull(errorMsg)) {
          errorMsg = JSON.stringify(err);
        }
      } else if (biqHelper.isNull(errorMsg)) {
        errorMsg = err.toString();
      }

    } catch (e) {
      errorMsg = 'Unknown Error';
    }
    this.toastr.error(errorMsg, 'Error');
  }

  errorGetDefault(err:any): Error {
    let errorMsg = _.get(err, 'message');
    try {

      if (biqHelper.isNull(errorMsg) && typeof err === 'object') {
        errorMsg = _.get(err, 'error.message');
        if (biqHelper.isNull(errorMsg)) {
          errorMsg = JSON.stringify(err);
        }
      } else if (biqHelper.isNull(errorMsg)) {
        errorMsg = err.toString();
      }

    } catch (e) {
      errorMsg = 'Unknown Error';
    }
    return new Error(errorMsg);
  }

  getAppointmentTypes(): Array<{ value: string; label: string }> {
    return [
      { value: AppointmentTypes.MEET_GREET, label: 'Meet & Greet' },
      { value: AppointmentTypes.ONBOARD_PARENT, label: 'Onboard Parent' },
      { value: AppointmentTypes.ONBOARD_PATIENT, label: 'Onboard Patient' },
      { value: AppointmentTypes.FOLLOW_UP_PARENT, label: 'Followup parent' },
      { value: AppointmentTypes.FOLLOW_UP_PATIENT, label: 'Followup patient' },
      { value: AppointmentTypes.OTHER, label: 'Other' },
    ]
  }

  getAppointmentPurposeLabel(value: string): string {
    const purposes = this.getAppointmentTypes();

    return purposes.filter(el => el.value === value)[0].label;
  }

}
