const actionPrefix = '[CaregiverInput]';

export namespace CaregiverInputActions {

    export class resetAll {
        static readonly type = `${actionPrefix} reset-all`;
    }

    export class AddSubmit {
        static readonly type = `${actionPrefix} add-submit`;
        constructor( public payload: { data: any; } ){}
    }

    export class CaregiverFetch {
        static readonly type = `${actionPrefix} caregiver-fetch`;
        constructor(public payload: {id: number}){}
    }

}