import { HttpClient } from "@angular/common/http";
import { Action, State, StateContext } from "@ngxs/store";
import { from, of } from "rxjs";
import { catchError, mergeMap } from "rxjs/operators";
import * as _ from "lodash";

import { environment } from "src/environments/environment";
import { PatientInputVideoActions } from "./patient-input-video.actions";
import { SharedService } from "src/app/shared/shared.service";
import { Injectable } from "@angular/core";
import { JoinInfoType } from "src/app/shared/interfaces/meeting.interface";
import { biqHelper } from "@biqdev/ng-helper";
import { InitAppService } from "src/app/shared/init-app.service";

export enum JoinInfoTrigger {
    BY_COACH = 'by-coach',
    BY_PATIENT_REQUEST = 'by-patient',
}

export enum RecordedStatus {
    MEETING_STARTING = "meeting-starting",//Local only not for DB Enum
    MEETING_STARTED = "meeting-started",
    MEETING_STOPPED = "meeting-stopped",
    PROCESSING = "record-processing",
    READY = "record-ready",
    FAILED = "record-failed",
}

export interface PatientInputVideoStateModel {
    socketJoinLoading: boolean;
    socketJoinLoaded: boolean;
    scheduleRecord: any;
    socketJoinErr: Error;

    meetingId: string;
    joinInfo: JoinInfoType;
    joinInfoLoading: boolean;
    joinInfoLoaded: boolean;
    joinEnfoError: Error;

    joinInfoSent: { date: Date; trigger: JoinInfoTrigger; joinInfo: JoinInfoType, serverResponse: any; error: Error }[];

    recordStarting: boolean;
    recordStarted: boolean;
    recordStartErr: Error;
    recordPipelineInfo: any;
    recordStoping: boolean;
    recordStoped: boolean;
    recordStopErr: Error;

    recordedStatus: RecordedStatus;
    recordedStatusChecking: boolean;
    recordedStatusChecked: boolean;
    recordedStatusCheckErr: Error;
    recordedVideoURL: { patient: string; coach: string };

    endSessionLoading: boolean;
    endSessionLoaded: boolean;
    endSessionRes: any;
    endSessionError: Error;
}

const defaultState: PatientInputVideoStateModel = {
    socketJoinLoading: false,
    socketJoinLoaded: false,
    scheduleRecord: {},
    socketJoinErr: null,

    meetingId: null,
    joinInfo: null,

    recordStarting: false,
    recordStarted: false,
    recordStartErr: null,
    recordPipelineInfo: null,
    recordStoping: false,
    recordStoped: false,
    recordStopErr: null,

    recordedStatus: null,
    recordedStatusChecking: false,
    recordedStatusChecked: false,
    recordedStatusCheckErr: null,
    recordedVideoURL: null,

    joinInfoLoading: false,
    joinInfoLoaded: false,
    joinEnfoError: null,
    joinInfoSent: [],
    endSessionLoading: false,
    endSessionLoaded: false,
    endSessionRes: null,
    endSessionError: null,
}

@State<PatientInputVideoStateModel>({
    name: 'PatientInputVideo',
    defaults: {
        ...defaultState
    }
})
@Injectable()
export class PatientInputVideoState {
    private _baseUrl = environment.url;
    io: any;


    constructor(
        private http: HttpClient,
        private sharedService: SharedService,
        private initAppService: InitAppService
    ) {
        this.io = this.initAppService.io;
    }

    @Action(PatientInputVideoActions.MeetingSocketJoin, {
        cancelUncompleted: true
    })
    meetingSocketJoin(ctx: StateContext<PatientInputVideoStateModel>, action: PatientInputVideoActions.MeetingSocketJoin) {
        ctx.patchState({
            socketJoinLoading: true,
            socketJoinLoaded: false
        });
        const socketJoin = new Promise((resolve, reject) => {
            this.io.socket.post(
                `${this._baseUrl}meeting/appointment/socket-join`,
                { meetingId: action.payload.meetingId },
                (res, jwRes) => {
                    if (res.success === true) {
                        resolve(res);
                    } else {
                        reject(res);
                    }
                }
            )
        });
        return from(socketJoin)
            .pipe(
                mergeMap((res: any) => {
                    return of(
                        ctx.dispatch(
                            new PatientInputVideoActions.MeetingSocketJoined({
                                scheduleRecord: res.schedule_record,
                                is_success: true
                            })
                        )
                    )
                }),
                catchError(err => {
                    return of(
                        ctx.dispatch(
                            new PatientInputVideoActions.MeetingSocketJoined({
                                scheduleRecord: {},
                                is_success: false,
                                error: err
                            })
                        )
                    )
                })
            );
    }

    @Action(PatientInputVideoActions.MeetingSocketJoined, {
        cancelUncompleted: true
    })
    meetingSocketJoined(ctx: StateContext<PatientInputVideoStateModel>, action: PatientInputVideoActions.MeetingSocketJoined) {
        ctx.patchState({
            socketJoinLoading: false,
            socketJoinLoaded: true,
            scheduleRecord: action.payload.scheduleRecord,
            socketJoinErr: action.payload.error,
            meetingId: action.payload.scheduleRecord['meeting_id']
        });
    }

    @Action(PatientInputVideoActions.MeetingStart, {
        cancelUncompleted: true
    })
    meetingStart(ctx: StateContext<PatientInputVideoStateModel>, action: PatientInputVideoActions.MeetingStart) {

        ctx.patchState({
            joinInfoLoading: true,
            joinInfoLoaded: false,
            joinInfo: null,
            recordStarted: false,
            recordedStatusChecked: false,
            recordedStatus: RecordedStatus.MEETING_STARTING,
        });

        return this.http.post(
            `${this._baseUrl}meeting`,
            {
                meetingId: ctx.getState().meetingId,
                coachId: action.payload.coachId.toString()
            }
        )
            .pipe(
                mergeMap(res => {
                    return of(
                        ctx.dispatch(
                            new PatientInputVideoActions.MeetingStarted({
                                joinInfo: res['JoinInfo'] as JoinInfoType,
                                is_success: true
                            })
                        )
                    )
                }),
                catchError(err => {
                    return of(
                        ctx.dispatch(
                            new PatientInputVideoActions.MeetingStarted({
                                joinInfo: null,
                                is_success: false,
                                error: err
                            })
                        )
                    )
                })
            );
    }

    @Action(PatientInputVideoActions.MeetingStarted, {
        cancelUncompleted: true
    })
    meetingStarted(ctx: StateContext<PatientInputVideoStateModel>, action: PatientInputVideoActions.MeetingStarted) {
        ctx.patchState({
            joinInfoLoading: false,
            joinInfoLoaded: true,
            joinInfo: action.payload.joinInfo,
            joinEnfoError: action.payload.error,
        });
        ctx.dispatch(new PatientInputVideoActions.JoinInfoSend({ trigger: JoinInfoTrigger.BY_COACH }));
    }

    @Action(PatientInputVideoActions.JoinInfoSend, { cancelUncompleted: true })
    joinInfoSend(ctx: StateContext<PatientInputVideoStateModel>, action: PatientInputVideoActions.JoinInfoSend) {

        const stateCurr: PatientInputVideoStateModel = ctx.getState();
        const callerName: string = this.getCallername(stateCurr.scheduleRecord);

        const joinInfoRes = new Promise((resolve, reject) => {
            this.io.socket.post(
                `${this._baseUrl}meeting/appointment/coach-send-meeting-join-info`,
                { meetingId: stateCurr.meetingId, meetingJoinInfo: stateCurr.joinInfo, callerName },
                (res, jwRes) => {
                    if (res.success === true) {
                        resolve(res);
                    } else {
                        reject(res);
                    }
                }
            );
        });

        return from(joinInfoRes)
            .pipe(
                mergeMap((res: any) => {
                    return of(
                        ctx.dispatch(
                            new PatientInputVideoActions.JoinInfoSent({
                                serverResponse: res,
                                trigger: action.payload.trigger
                            })
                        )
                    )
                }),
                catchError(err => {
                    return of(
                        ctx.dispatch(
                            new PatientInputVideoActions.JoinInfoSent({
                                serverResponse: null,
                                trigger: action.payload.trigger,
                                error: err
                            })
                        )
                    )
                })
            );



    }

    @Action(PatientInputVideoActions.JoinInfoSent, { cancelUncompleted: true })
    joinInfoSent(ctx: StateContext<PatientInputVideoStateModel>, action: PatientInputVideoActions.JoinInfoSent) {
        const stateCurr = ctx.getState();
        ctx.patchState({
            joinInfoSent: [
                ...stateCurr.joinInfoSent,
                {
                    date: new Date(),
                    joinInfo: stateCurr.joinInfo,
                    serverResponse: action.payload.serverResponse,
                    trigger: action.payload.trigger as JoinInfoTrigger,
                    error: action.payload.error,
                }
            ]
        });
    }

    @Action(PatientInputVideoActions.MeetingRecordStart, { cancelUncompleted: true })
    meetingRecordStart(ctx: StateContext<PatientInputVideoStateModel>) {
        ctx.patchState({
            recordStarting: true,
            recordStarted: false,
        });
        return this.http.post(
            `${this._baseUrl}meeting/record-start`,
            {
                meetingId: ctx.getState().meetingId
            }
        )
            .pipe(
                mergeMap(res => {
                    return of(
                        ctx.dispatch(
                            new PatientInputVideoActions.MeetingRecordStarted({
                                pipelineInfo: res['pipelineInfo']
                            })
                        )
                    )
                }),
                catchError(err => {
                    return of(
                        ctx.dispatch(
                            new PatientInputVideoActions.MeetingRecordStarted({
                                pipelineInfo: null,
                                error: null
                            })
                        )
                    )
                })
            );
    }

    @Action(PatientInputVideoActions.MeetingRecordStarted, { cancelUncompleted: true })
    meetingRecordStarted(ctx: StateContext<PatientInputVideoStateModel>, action: PatientInputVideoActions.MeetingRecordStarted) {
        ctx.patchState({
            recordStarting: false,
            recordStarted: true,
            recordStartErr: action.payload.error,
            recordPipelineInfo: action.payload.pipelineInfo
        });

        if ( action.payload.error ) {
            ctx.patchState({
                recordedStatus: RecordedStatus.FAILED,
            });
        }

    }

    @Action(PatientInputVideoActions.MeetingRecordStop, { cancelUncompleted: true })
    meetingRecordStop(ctx: StateContext<PatientInputVideoStateModel>) {

        const stateCurr = ctx.getState();

        if (biqHelper.isNull(stateCurr.recordPipelineInfo)) {
            console.info('No pipeline to stop');
            return;
        }

        ctx.patchState({
            recordStoping: true,
            recordStoped: false,
        });

        return this.http.post(
            `${this._baseUrl}meeting/record-stop`,
            {
                mediaPipelineId: ctx.getState().recordPipelineInfo.MediaCapturePipeline.MediaPipelineId
            }
        )
            .pipe(
                mergeMap(res => {
                    return of(
                        ctx.dispatch(
                            new PatientInputVideoActions.MeetingRecordStoped({
                                error: null
                            })
                        )
                    )
                }),
                catchError(err => {
                    return of(
                        ctx.dispatch(
                            new PatientInputVideoActions.MeetingRecordStoped({
                                error: err
                            })
                        )
                    )
                })
            );
    }

    @Action(PatientInputVideoActions.MeetingRecordStoped, { cancelUncompleted: true })
    meetingRecordStoped(ctx: StateContext<PatientInputVideoStateModel>, action: PatientInputVideoActions.MeetingRecordStoped) {
        ctx.patchState({
            recordStoping: false,
            recordStoped: true,
            recordStartErr: action.payload.error,
        });
    }

    @Action(PatientInputVideoActions.MeetingSessionEnd, { cancelUncompleted: true })
    meetingSessionEnd(ctx: StateContext<PatientInputVideoStateModel>) {
        ctx.patchState({
            endSessionLoading: true,
            endSessionLoaded: false,
        });

        const stateCurr = ctx.getState();

        return this.http.delete(
            `${this._baseUrl}meeting/${stateCurr.meetingId}`,
        )
            .pipe(
                mergeMap(res => {
                    return of(
                        ctx.dispatch(
                            new PatientInputVideoActions.MeetingSessionEnded({
                                result: res
                            })
                        )
                    );
                }),
                catchError(err => {
                    return of(
                        ctx.dispatch(
                            new PatientInputVideoActions.MeetingSessionEnded({
                                result: null,
                                error: err
                            })
                        )
                    )
                })
            );
    }

    @Action(PatientInputVideoActions.MeetingSessionEnded, { cancelUncompleted: true })
    meetingSessionEnded(ctx: StateContext<PatientInputVideoStateModel>, action: PatientInputVideoActions.MeetingSessionEnded) {
        ctx.patchState({
            endSessionLoading: false,
            endSessionLoaded: true,
            endSessionRes: action.payload.result,
            endSessionError: action.payload.error
        });
    }

    @Action(PatientInputVideoActions.MeetingRecordedCheck, { cancelUncompleted: true })
    meetingRecordedCheck(ctx: StateContext<PatientInputVideoStateModel>, action: PatientInputVideoActions.MeetingRecordedCheck) {
        ctx.patchState({
            recordedStatusChecking: true,
            recordedStatusChecked: false,
        });

        return this.http.get(`${this._baseUrl}meeting/chime-status-check/${action.payload.meetingId}`)
            .pipe(
                mergeMap(res => {
                    return of(
                        ctx.dispatch(
                            new PatientInputVideoActions.MeetingRecordedChecked({
                                chimeStatus: _.get(res, 'data.chimeStatus'),
                                videoURLs: _.get(res, 'data.videoUrls'),
                                error: _.get(res, 'data.error'),
                            })
                        )
                    )
                }),
                catchError(err => {
                    return of(
                        ctx.dispatch(
                            new PatientInputVideoActions.MeetingRecordedChecked({
                                chimeStatus: null,
                                videoURLs: null,
                                error: err
                            })
                        )
                    )
                })
            );
    }
    @Action(PatientInputVideoActions.MeetingRecordedChecked, { cancelUncompleted: true })
    meetingRecordedChecked( ctx: StateContext<PatientInputVideoStateModel>, action: PatientInputVideoActions.MeetingRecordedChecked ) {
        ctx.patchState({
            recordedStatus: action.payload.chimeStatus as RecordedStatus,
            recordedStatusChecking: false,
            recordedStatusChecked: true,
            recordedStatusCheckErr: action.payload.error,
            recordedVideoURL: action.payload.videoURLs
        });
    }

    getCallername(scheduleRecord: any): string {
        if (!biqHelper.isNull(scheduleRecord.caller_name)) {
            return scheduleRecord.caller_name;
        } else if (!biqHelper.isNull(scheduleRecord.patient_firstname)) {
            let name = scheduleRecord.patient_firstname;
            if (!biqHelper.isNull(scheduleRecord.patient_lastname)) {
                name = `${name} ${scheduleRecord.patient_firstname}`;
            }
            return name;
        } else {
            return 'Getting caller name...';
        }
    }

}