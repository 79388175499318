import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import Swal from 'sweetalert2';

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

const leaveConfirm = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-danger mr-3',
    cancelButton: 'btn btn-secondary'
  },
  buttonsStyling: false
})

@Injectable()
export class UnsavedChangesGuard implements CanDeactivate<ComponentCanDeactivate> {
  canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
    // if there are no pending changes, just allow deactivation; else confirm first
    let forceLeave$: Observable<boolean> = new Observable( subscriber => {
      leaveConfirm.fire({
        title: 'Warning',
        text: 'You have unsaved changes. are you sure to leave? Please click "Cancel" if you like to save changes before leaving.',
        showCancelButton: true,
        confirmButtonText: 'Yes, leave',
        cancelButtonText: 'Cancel'
      })
        .then((result) => {
          subscriber.next(result.isConfirmed);
          subscriber.complete();
        });
    });
    return component.canDeactivate() ?
      true :
      forceLeave$;
  }
}