// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  local_dev: false,
  url: 'https://api.mitra.biqdev.com/',
  //url: 'http://3.83.230.195:4095/',
  mysql_moment_date_format: 'YYYY-MM-DD HH:mm:ss',
  key: 'onlineconsultations987654321',
  apiKey: "47309184",
  // Difining algorithm
  algorithm: 'AES256',
  hmr: false,
  // url: 'http://localhost:1337/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
