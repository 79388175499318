import { Injectable } from "@angular/core";
import { State } from "@ngxs/store";
import { CaregiverInputState } from "../caregiver-input/store/caregiver-input.state";
import { PatientInputState } from "../patient-input/store/patient-input.state";
import { PatientInputVideoState } from "../patient-input/video/store/patient-input-video.state";

export interface PatientManagementStateModel {

}

const defaultState: PatientManagementStateModel = {}

@State<PatientManagementStateModel>({
    name: 'PatientManagement',
    defaults: {
        ...defaultState
    },
    children: [ PatientInputState, PatientInputVideoState, CaregiverInputState ]
})
@Injectable()
export class PatientManagementState {
    
}