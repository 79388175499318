import { HttpClient } from "@angular/common/http";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { of } from "rxjs";
import { catchError, mergeMap, tap } from "rxjs/operators";
import * as _ from "lodash";

import { environment } from "src/environments/environment";
import { PatientInputActions } from "./patient-input.actions";
import { Injectable } from "@angular/core";

export interface PatientInputStateModel {
    addSubmitting: boolean;
    addSubmitted: boolean;
    addResponse: any;
    addSubmitErr: Error;

    patientFetching: boolean;
    patientFetched: boolean;
    patientFetchResponse: any;
    patientFetchErr: Error;
}

const defaultState: PatientInputStateModel = {
    addSubmitting: false,
    addSubmitted: false,
    addResponse: null,
    addSubmitErr: null,

    patientFetching: false,
    patientFetched: false,
    patientFetchResponse: null,
    patientFetchErr: null
}

@State<PatientInputStateModel>({
    name: 'PatientInput',
    defaults: {
        ...defaultState
    }
})
@Injectable()
export class PatientInputState {
    private _baseUrl = environment.url;

    constructor(
        private http: HttpClient,
    ) { }

    @Action(PatientInputActions.resetAll)
    resetAll(ctx: StateContext<PatientInputStateModel>) {
        ctx.setState({ ...defaultState });
    }

    @Action(PatientInputActions.AddSubmit, { cancelUncompleted: true })
    addSubmit(ctx: StateContext<PatientInputStateModel>, action: PatientInputActions.AddSubmit) {

        ctx.patchState({
            addSubmitting: true,
            addSubmitted: false,
            addResponse: null,
            addSubmitErr: null
        });

        return this.http.post(`${this._baseUrl}patient`, action.payload.data)
            .pipe(
                tap(e => {
                    ctx.patchState({
                        addSubmitting: false,
                        addSubmitted: true,
                        addResponse: e,
                        addSubmitErr: null
                    });
                }),
                catchError(err => {
                    return of(
                        ctx.patchState({
                            addSubmitting: false,
                            addSubmitted: true,
                            addResponse: null,
                            addSubmitErr: err
                        })
                    );
                })
            );

    }

    @Action(PatientInputActions.PatientFetch, { cancelUncompleted: true })
    patientFetch(ctx: StateContext<PatientInputStateModel>, action: PatientInputActions.PatientFetch) {
        ctx.patchState({
            patientFetching: true,
            patientFetched: false,
            patientFetchResponse: null,
            patientFetchErr: null,
        });
        return this.http.get(`${this._baseUrl}patient/${action.payload.id}`)
            .pipe(
                tap(e => {
                    ctx.patchState({
                        patientFetching: false,
                        patientFetched: true,
                        patientFetchResponse: e,
                        patientFetchErr: null,
                    });
                }),
                catchError(err => {
                    return of(
                        ctx.patchState({
                            patientFetching: false,
                            patientFetched: true,
                            patientFetchResponse: null,
                            patientFetchErr: err,
                        })
                    );
                })
            )
    }

}