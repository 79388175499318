const actionPrefix = '[PatientInput]';


export namespace PatientInputActions {
    export class resetAll {
        static readonly type = `${actionPrefix} reset-all`;
    }
    export class AddSubmit {
        static readonly type = `${actionPrefix} add-submit`;
        constructor( public payload: { data: any; } ){}
    }

    export class PatientFetch {
        static readonly type = `${actionPrefix} patient-fetch`;
        constructor(public payload: {id: number}){}
    }

}