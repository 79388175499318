import { Component } from '@angular/core';

import { ScrollTopService } from './scrolltop.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'coach_online_consultation';

  constructor( private scrollTopService: ScrollTopService
    ) {}

    ngOnInit() {
      this.scrollTopService.setScrollTop();
      }
}
