import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class BehaviorService {

    public userData: BehaviorSubject<object> = new BehaviorSubject<object>({});


    rootUrl: string = environment.url;

    constructor() { }


    setUserData(data) {
        this.userData.next(data);
    }

    getUserData() {
        return this.userData.asObservable();
    }

}
