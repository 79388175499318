import { CaregiverInputState } from "../patient-management/caregiver-input/store/caregiver-input.state";
import { PatientInputState } from "../patient-management/patient-input/store/patient-input.state";
import { PatientInputVideoState, PatientInputVideoStateModel } from "../patient-management/patient-input/video/store/patient-input-video.state";
import { PatientManagementState, PatientManagementStateModel } from "../patient-management/store/patient-management.state";
import { ProvidersState, ProvidersStateModel } from "./providers.state";

export interface AppState {
    Providers: ProvidersStateModel,
    PatientManagement: PatientManagementStateModel,
    PatientInputVideo: PatientInputVideoStateModel,
    PatientInput: PatientInputState,
    CaregiverInput: CaregiverInputState,
}

export const AppStore = [
    ProvidersState,
    PatientManagementState,
    PatientInputVideoState,
    PatientInputState,
    CaregiverInputState,
];