import { HttpClient } from "@angular/common/http";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { of } from "rxjs";
import { catchError, mergeMap } from "rxjs/operators";
import * as _ from "lodash";

import { environment } from "src/environments/environment";
import { ProvidersActions } from "./providers.actions";
import { Injectable } from "@angular/core";
import { SharedService } from "../shared/shared.service";

export interface ProvidersStateModel {
    countries: {
        is_loading: boolean;
        is_loaded: boolean;
        data: any[];
        error: Error;
    }
}

const defaultState: ProvidersStateModel = {
    countries: {
        is_loading: false,
        is_loaded: false,
        data: [],
        error: null,
    }
}

@State<ProvidersStateModel>({
    name: 'Provider',
    defaults: {
        ...defaultState
    }
})
@Injectable()
export class ProvidersState {
    private _baseUrl = environment.url;

    constructor(
        private http: HttpClient,
        private sharedService: SharedService
    ) { }

    @Selector()
    static countries( state: ProvidersStateModel ) {
        return state.countries;
    }

    @Action(ProvidersActions.CountriesFetch, {
        cancelUncompleted: true
    })
    CountriesFetch(ctx: StateContext<ProvidersStateModel>) {
        ctx.patchState({
            countries: {
                is_loading: true,
                is_loaded: false,
                data: [],
                error: null
            }
        });

        return this.http.get(`${this._baseUrl}countries`)
            .pipe(
                mergeMap(res => {
                    return of(
                        ctx.dispatch(
                            new ProvidersActions.CountriesFetched({
                                data: _.get(res, 'data'),
                                is_success: true
                            })
                        )
                    )
                }),
                catchError( err => {
                    return of(
                        ctx.dispatch(
                            new ProvidersActions.CountriesFetched({
                                data: [],
                                is_success: false,
                                error: err
                            })
                        )
                    )
                } )
            );
    }

    @Action( ProvidersActions.CountriesFetched, {
        cancelUncompleted: true
    } )
    CountriesFetched(ctx: StateContext<ProvidersStateModel>, action: ProvidersActions.CountriesFetched) {
        ctx.patchState({
            countries: {
                is_loading: false,
                is_loaded: true,
                data: action.payload.data,
                error: action.payload.error
            }
        });

        if ( !_.isNil(action.payload.error) ) {
            this.sharedService.errorHandlerDefault(action.payload.error);
        }
        
    }

}