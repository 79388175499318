import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable()
export class InitAppService {

  io: any = { socket: null };

  getIO() {
    return this.io;
  }

  initSocketIO(force: boolean = false): Promise<string> {

    return new Promise((resolve, reject) => {

      if (!force && this.io && this.io.socket && this.io.socket.isConnected()) {
        if (environment.local_dev) console.info('previously connected');
        resolve('previously connected');
        return;
      }

      if (!force && this.io && this.io.socket && this.io.socket.isConnecting()) {
        return;
      }

      if (force && this.io.socket.isConnecting()) {
        this.io.socket.disconnect();
      }

      let currentUserData: any = JSON.parse(localStorage.getItem('credentials'));
      let headers = {};
      if (currentUserData) {
        const token = currentUserData.access_token ? currentUserData.access_token : '';
        if (token) {
          headers = {
            'Authorization': `Bearer ${token}`,
            'Access-Control-Allow-Origin': '*',
            'Accept': 'application/json',
          };
        }
      }

      const webSocketUrl = environment.url.substring(0, environment.url.length - 1);
      this.io.socket = window['io'].sails.connect(webSocketUrl, { headers: headers });

      this.io.socket.on('connect', function onConnect() {
        if (environment.local_dev) console.info('Connected');
        resolve('Connected');
      });

    });
  }

  socketDisconnect(): void {

    if (this.io.socket && this.io.socket.isConnected()) {
      this.io.socket.disconnect();
    }

  }


}