import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
// Layouts
// import { ThemeModule } from './theme/theme.module';

const routes: Routes = [
  
  {
    path: '',
    // loadChildren: './theme/theme.module#ThemeModule',
    loadChildren:() =>  import('./theme/theme.module').then(m => m.ThemeModule),
  },
];


@NgModule({
  imports: [RouterModule.forRoot(
    routes,
    {
    preloadingStrategy: PreloadAllModules,
    relativeLinkResolution: 'legacy'
}
  )],
  exports: [ RouterModule,HttpClientModule ]
})
export class AppRoutingModule { }
