const actionPrefix = '[Providers]';

export namespace ProvidersActions {
    export class CountriesFetch {
        static readonly type = `${actionPrefix} countries-fetch`;
    }
    
    export class CountriesFetched {
        static readonly type = `${actionPrefix} countries-fetched`;
        constructor(public payload: { data: any[]; is_success: boolean; error?: Error; }){}
    }
}