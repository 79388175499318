import { JoinInfoType } from "src/app/shared/interfaces/meeting.interface";

const actionPrefix = '[PatientInputVideo]';

export namespace PatientInputVideoActions {
    export class MeetingSocketJoin {
        static readonly type = `${actionPrefix} meeting-socket-join`;
        constructor(public payload: { meetingId: string }) { }
    }

    export class MeetingSocketJoined {
        static readonly type = `${actionPrefix} meeting-socket-joined`;
        constructor(public payload: { scheduleRecord: Object, is_success: boolean; error?: Error }) { }
    }

    export class MeetingStart {
        static readonly type = `${actionPrefix} meeting-start`;
        constructor(public payload: { coachId: number; }) { }
    }

    export class MeetingStarted {
        static readonly type = `${actionPrefix} meeting-started`;
        constructor(public payload: { joinInfo: JoinInfoType; is_success: boolean; error?: Error }) { }
    }

    export class JoinInfoSend {
        static readonly type = `${actionPrefix} join-info-send`;
        constructor(public payload: {trigger: string}){}
    }

    export class JoinInfoSent {
        static readonly type = `${actionPrefix} join-info-sent`;
        constructor(public payload: { serverResponse: any; trigger: string; error?: Error }) { }
    }

    export class MeetingSessionEnd {
        static readonly type = `${actionPrefix} meeting-session-end`;
    }

    export class MeetingSessionEnded {
        static readonly type = `${actionPrefix} meeting-session-ended`;
        constructor(public payload: { result: any; error?: Error }) { }
    }

    export class MeetingRecordStart {
        static readonly type = `${actionPrefix} meeting-record-start`;
    }

    export class MeetingRecordStarted {
        static readonly type = `${actionPrefix} meeting-record-started`;
        constructor(public payload: { pipelineInfo: any; error?: Error }){}
    }

    export class MeetingRecordStop {
        static readonly type = `${actionPrefix} meeting-record-stop`;
    }

    export class MeetingRecordStoped {
        static readonly type = `${actionPrefix} meeting-record-stoped`;
        constructor(public payload: {error: Error}){}
    }

    export class MeetingRecordedCheck {
        static readonly type = `${actionPrefix} meeting-recorded-check`;
        constructor(public payload: {meetingId: string;}){}
    }

    export class MeetingRecordedChecked {
        static readonly type = `${actionPrefix} meeting-recorded-checked`;
        constructor(public payload: { chimeStatus: string; videoURLs: any; error?: Error }){}
    }

}