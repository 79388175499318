import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { UserIdleModule } from 'angular-user-idle';
import { gsap } from 'gsap';
import { TextPlugin } from 'gsap/TextPlugin';
import { Draggable } from 'gsap/Draggable';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';

import { AppStore } from './store';
import { ErrorInterceptor } from './shared/error.interceptor';
import { AuthInterceptor } from './shared/auth-interceptor';
import { BehaviorService } from './shared/behavior.service';
import { ScrollTopService } from './scrolltop.service';
// import { StriphtmlPipe } from './shared/pipes/striphtml.pipe';
import { SharedService } from './shared/shared.service';
import { WindowRef } from './shared/window.service';
import { environment } from 'src/environments/environment';
import { InitAppService } from './shared/init-app.service';
import { UnsavedChangesGuard } from './shared/unsaved-changes.guard';
import { CommonModule } from '@angular/common';

gsap.registerPlugin(Draggable, TextPlugin);

@NgModule({
  declarations: [
    AppComponent,
    // NumbersOnlyDirective,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule,
    AppRoutingModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      preventDuplicates: true,
      positionClass: 'toast-bottom-right',
    }),
    UserIdleModule.forRoot({
      idle: 650
      , timeout: 1
    }),
    BrowserAnimationsModule,
    HttpClientModule,
    NgxsModule.forRoot(AppStore, { developmentMode: !environment.production }),
    !environment.production ? NgxsReduxDevtoolsPluginModule.forRoot() : [],
  ],
  providers: [
    BehaviorService,
    ScrollTopService,
    SharedService,
    InitAppService,
    WindowRef,
    UnsavedChangesGuard,
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: (socketioService: InitAppService) => () => socketioService.initSocketIO(),
      deps: [InitAppService],
      multi: true,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    private winRef: WindowRef,
    private sharedService: SharedService
  ) {
  }


}
