import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Action, State, StateContext } from "@ngxs/store";
import { of } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { CaregiverInputActions } from "./caregiver-input.actions";

export interface CaregiverInputStateModel {
    addSubmitting: boolean;
    addSubmitted: boolean;
    addResponse: any;
    addSubmitErr: Error;

    caregiverFetching: boolean;
    caregiverFetched: boolean;
    caregiverFetchResponse: any;
    caregiverFetchErr: Error;

}

const defaultState: CaregiverInputStateModel = {
    addSubmitting: false,
    addSubmitted: false,
    addResponse: null,
    addSubmitErr: null,

    caregiverFetching: false,
    caregiverFetched: false,
    caregiverFetchResponse: null,
    caregiverFetchErr: null
}

@State<CaregiverInputStateModel>({
    name: 'CaregiverInput',
    defaults: {
        ...defaultState
    }
})
@Injectable()
export class CaregiverInputState {
    private _baseUrl = environment.url;

    constructor(
        private http: HttpClient,
    ){}

    @Action(CaregiverInputActions.resetAll)
    resetAll(ctx: StateContext<CaregiverInputStateModel>) {
        ctx.setState({...defaultState});
    }

    @Action(CaregiverInputActions.AddSubmit)
    addSubmit(ctx: StateContext<CaregiverInputStateModel>, action: CaregiverInputActions.AddSubmit) {

        ctx.patchState({
            addSubmitting: true,
            addSubmitted: false,
            addResponse: null,
            addSubmitErr: null
        });

        return this.http.post(`${this._baseUrl}caregiver`, action.payload.data)
            .pipe(
                tap( e => {
                    ctx.patchState({
                        addSubmitting: false,
                        addSubmitted: true,
                        addResponse: e,
                        addSubmitErr: null
                    });
                }),
                catchError(err => {
                    return of(
                        ctx.patchState({
                            addSubmitting: false,
                            addSubmitted: true,
                            addResponse: null,
                            addSubmitErr: err
                        })
                    )
                })
            )

    }
}